import React from 'react'
import { Helmet } from 'react-helmet'
import {
  Container,
  Row,
  Col,
  List,
  ListGroup,
  ListGroupItem,
  Card,
  CardImg,
} from 'reactstrap'
import Layout from '../components/layout'
import buchla700 from '../images/buchla-700-main-screen.jpg'

class Faq extends React.PureComponent {
  render() {
    return (
      <ListGroupItem className="border border-secondary">
        <h4>
          <span>{this.props.q}</span>
        </h4>
        {this.props.children}
      </ListGroupItem>
    )
  }
}

const FaqPage = () => (
  <Layout>
    <Container fluid>
      <Helmet title="faq" />
      <Row noGutters className="text-center mt-4">
        <h3>Frequently Asked Questions</h3>
      </Row>

      <Row noGutters className="text-center mt-4">
        <Col className="text-left">
          <ListGroup>
            <Faq q="What is ID700?">
              <p>ID700 is a software synthesizer inspired by the Buchla 700.</p>
            </Faq>
            <Faq q="What happened to Instrument Designer?">
              <p>
                ID700 was formerly known as Instrument Designer, which was the
                name of the main interface on the Buchla 700.
              </p>
              <Card>
                <CardImg src={buchla700} />
              </Card>
              <p>
                That name wasn't ideal for several reasons but the deal-breaker
                was being too long for a proper iOS name ("Instrument D....." is
                not quite the same).
              </p>
            </Faq>
            <Faq q="Ok, what is the Buchla 700?">
              <p>
                The Buchla 700 is a 12 voice 4 operator FM synthesizer released
                in 1987 and designed by Don Buchla. There are several main ways
                the 700 differentiates itself from the other FM synths common in
                the industry at that time:
              </p>
              <List>
                <li>
                  Each algorithm outputs through two independent waveshapers.
                  This allows a single instrument definition to output two
                  different audio signals without requiring a separate stacked
                  voice. This also increases the sonic palette by allowing a
                  user to easily create non-sinusoid waveforms.
                </li>
                <li>
                  The waveshapers are cousins to the ones used in the Buchla
                  Touché, Buchla 400 and the Buchla 259e. They are quite unique
                  and use some&nbsp;
                  <a href="http://sites.music.columbia.edu/cmc/MusicAndComputers/chapter4/04_06.php">
                    interesting waveshaping techniques
                  </a>
                  .
                </li>
                <li>
                  Each voice has 13 complex envelopes which control almost every
                  aspect of the sound algorithms. The envelopes have a unique
                  exponential display, are programmable with an arbitrary number
                  of breakpoints, and are jumpable/loopable.
                </li>
                <li>
                  Programming was simplified with the help of a graphical user
                  interface and an external monitor.
                </li>
              </List>
            </Faq>
            <Faq q="Is ID700 a clone/emulation of the Buchla 700?">
              ID700 is not a clone or emulation of the Buchla 700. ID700 is a
              modern interpretation of what I found interesting in the Buchla
              700. Specifically, I focused on creating sounds for a single voice
              ("Instrument" in Buchla 700 terminology).
            </Faq>
            <Faq q="What does the Buchla 700 sound like?">
              <p>
                There weren't a lot of these produced, so there's not that much
                out there. What is out there is quite interesting:
              </p>
              <ul>
                <li>
                  <a href="https://shastacults.bandcamp.com/album/configurations">
                    Shasta Cults &#8212; Configurations
                  </a>
                </li>
                <li>
                  <a href="https://charles-cohen.bandcamp.com/track/generator">
                    Charles Cohen &#8212; Generator
                  </a>
                </li>
                <li>
                  <a href="https://zackdagoba.bandcamp.com/album/chimeror">
                    Benge &#8212; Chimeror
                  </a>
                </li>
                <li>
                  <a href="https://archive.org/details/OTG_1988_03_21">
                    Ode To Gravity
                  </a>{' '}
                  &#8212; this is from the release party for the 700 and
                  includes interviews with Don
                </li>
              </ul>
            </Faq>
            <Faq q="So what happened to the Buchla 700?">
              <p>
                The instrument never became very popular and today is one of the
                rarer of Don Buchla's instruments (which says a lot). The
                software itself was never finished, and the video card which
                connected up the external display was unreliable and eventually
                end-of-lifed by Intel. Probably 10-15 of these were made - I
                personally know of 3, of which only 2 currently work.
              </p>
            </Faq>
            <Faq q="Why hasn't anyone made a Buchla 700 reissue?">
              <p>
                Good question. The design is quite complicated and includes 4
                separate computers as well as a large membrane input surface. It
                would certainly be possible to build but it wouldn't be a cheap
                endeavor, and unlike other recent reissues it probably wouldn't
                be a big seller - it's likely the most difficult instrument of
                Don's to use.
              </p>
            </Faq>
            <Faq q="So why are you bothering with ID700?">
              <p>
                I love the sound of Don's wavetable synths (specifically the
                259e) and I've spent a lot of time implementing various related
                algorithms. I had the opportunity to play around with a 700 a
                few years back and the idea was hatched there.
              </p>
            </Faq>
            <Faq q="Are you using the original Buchla 700 source code?">
              <p>
                No. This entire project was implemented using a scan of the
                original 700 manual as the spec (which was the usual Don way of
                doing things). You can view what survives from the original
                source code{' '}
                <a href="http://lanterman.ece.gatech.edu/buchla700/">here</a>.
                It's mostly UI and low-level hardware utilities, but it
                seemingly contains code used for a NASA project as well:
              </p>
              <p>
                <code>
                  Set ON_B700 non-zero to get a Buchla 700 PROM, or zero to get
                  a NASA 3D Helmet Display PROM.
                </code>
              </p>
              <p>
                There is a project that's managed to{' '}
                <a href="https://bob.lopatic.de/trac">get it to boot</a>.
                Unfortunately , since the source code doesn't include any DSP
                there's no sound.
              </p>
            </Faq>
            <Faq q="Where can I learn more about the Buchla 700?">
              <List>
                <li>
                  <a href="http://lanterman.ece.gatech.edu/buchla700/">
                    The Buchla 700 Preservation Page
                  </a>
                </li>
                <li>
                  <a href="https://gliftor.wordpress.com/articles/notes-on-the-buchla-700-audio-synthesizer/">
                    Notes on the Buchla 700 audio synthesizer
                  </a>
                </li>
                <li>
                  <a href="http://randomvoltage.com/700/">
                    Buchla 700 archeology
                  </a>
                </li>
                <li>
                  <a href="https://encyclotronic.com/synthesizers/buchla/700-series-r1026/">
                    Buchla 700
                  </a>
                </li>
                <li>
                  <a href="https://zackdagoba.bandcamp.com/album/chimeror">
                    Chimeror by Benge
                  </a>
                </li>
                <li>
                  <a href="https://bob.lopatic.de/trac">
                    The Buchla 700 Emulation Project
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/watch?v=QczxCxFRUf0">
                    Alessandro Cortini of Nine Inch Nails playing a Buchla 700
                    in Twin Peaks
                  </a>{' '}
                  &#8212; I don't know if the sound is actually 700 or not but
                  who cares?
                </li>
                <li>
                  <a href="https://github.com/Rishikeshdaoo/vBuchla-700">
                    vBuchla-700
                  </a>
                  &nbsp;- a Buchla 700 Recreation Project
                </li>
                <li>
                  <a href="https://github.com/lantertronics/b700ish">b700ish</a>
                  &nbsp;- A rough approximation of the Buchla 700 voice
                  architecture in the Supercollider programming language from
                  Aaron Lanterman
                </li>
              </List>
            </Faq>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default FaqPage
